import { Breadcrumbs, styled, Typography } from '@mui/material';
import { Route } from 'next';

import { BodyContainer } from './BodyContainer';
import { Link } from './Link';

export type Breadcrumb = {
  label: string;
  href: string | Route;
};

interface NavBreadcrumbsProps {
  breadcrumbs?: Breadcrumb[];
}

const BreadcrumbLink = styled(Link)<{ index: number; totalLength: number }>(({
  theme,
  index,
  totalLength,
}) => {
  const isLast = index === totalLength - 1;
  return {
    color: isLast ? theme.palette.text.primary : theme.palette.text.secondary,
    ':hover': {
      color: isLast ? theme.palette.text.secondary : theme.palette.text.primary,
    },
  };
});

export function NavBreadcrumbs({ breadcrumbs }: NavBreadcrumbsProps) {
  if (!breadcrumbs || !breadcrumbs.length) {
    return null;
  }

  return (
    <BodyContainer>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          marginTop: { xs: 2, md: 0 },
        }}
      >
        {breadcrumbs.map(({ label, href }, index) => (
          <BreadcrumbLink
            href={href}
            index={index}
            key={href}
            totalLength={breadcrumbs.length}
            underline={index === breadcrumbs.length - 1}
            useCustomHover
          >
            <Typography component="span" variant="caption">
              {label}
            </Typography>
          </BreadcrumbLink>
        ))}
      </Breadcrumbs>
    </BodyContainer>
  );
}
