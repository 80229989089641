import { styled, Typography } from '@mui/material';

import { Link } from '~/components/Link';
import { beholdenFont } from '~/constants/theme';
import { pxToRem } from '~/libs/style';

const LinkText = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: pxToRem(18),
  fontFamily: beholdenFont.style.fontFamily,
}));

interface NavLinkProps {
  href: string;
  label: string;
}

export function NavLink({ href, label }: NavLinkProps) {
  return (
    <Link href={href} underline={false}>
      <LinkText as="span">{label}</LinkText>
    </Link>
  );
}
