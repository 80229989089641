import { Box, styled, Typography, useTheme } from '@mui/material';

import { beholdenFont } from '~/constants/theme';
import { pxToRem } from '~/libs/style';
import { useBreakpointDown } from '~/libs/theme/hooks';

import { BodyContainerInner, BodyContainerOuter } from '../BodyContainer';
import { VariablePublicImage } from '../VariablePublicImage';

const ImageBox = styled(Box)`
  filter: brightness(0.55);
  opacity: 0.7;
  mask-image: linear-gradient(transparent, rgba(0, 0, 0, 1), transparent);
`;

type ObjectPosition = `${number}% ${number}%`;

export interface NavBannerProps {
  title: string;
  imageSrc?: string;
  imageAlt?: string;
  objectPosition?: ObjectPosition;
}

export function NavBanner({
  title,
  imageSrc,
  imageAlt,
  objectPosition = '0% 40%',
}: Partial<NavBannerProps>) {
  const smDown = useBreakpointDown('sm');
  const theme = useTheme();

  if (!imageSrc && !title) {
    return null;
  }

  return (
    <BodyContainerOuter
      alignItems="center"
      height={{ xs: pxToRem(90), md: pxToRem(140) }}
      marginBottom={{ xs: 0, md: theme.spacing(2) }}
    >
      {imageSrc && imageAlt && (
        <ImageBox
          height="100%"
          left={0}
          overflow="hidden"
          position="absolute"
          right={0}
          top={0}
          width="100%"
          zIndex={-1}
        >
          <VariablePublicImage
            alt={imageAlt}
            fill
            priority
            src={imageSrc}
            style={{
              objectFit: 'cover',
              objectPosition,
            }}
          />
        </ImageBox>
      )}
      {Boolean(title) && (
        <BodyContainerInner paddingY={2}>
          <Typography
            className={beholdenFont.className}
            component="h2"
            fontFamily={beholdenFont.style.fontFamily}
            fontWeight={500}
            lineHeight={0.95}
            sx={{
              // Kinda guarantees contrasts with the background of the image
              textShadow: `1px 1px 1px ${theme.palette.background.default}`,
            }}
            variant={smDown ? 'h5' : 'h4'}
          >
            {title}
          </Typography>
        </BodyContainerInner>
      )}
    </BodyContainerOuter>
  );
}
