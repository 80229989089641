import { ManageAccounts } from '@mui/icons-material';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import MuiMenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  styled,
} from '@mui/material';
import { signIn, signOut } from 'next-auth/react';
import { Fragment, useState } from 'react';

import { USERS_SETTINGS_ROUTE } from '~/constants/routes';
import { useBreakpointUp } from '~/libs/theme/hooks';

import { BodyContainerInner, BodyContainerOuter } from '../BodyContainer';
import { HomeLink } from '../HomeLink';
import { Link } from '../Link';
import { NavLink } from './components/NavLink';
import { NavProgress } from './components/NavProgress';
import { NAV_LINKS } from './constants';

const NavHeader = styled(BodyContainerOuter)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
}));

const LogoRow = styled(BodyContainerInner)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
}));

const NavBase = LogoRow.withComponent('ul');

const Nav = styled(NavBase)(({ theme }) => ({
  gap: theme.spacing(2),
  listStyle: 'none',
  padding: `0 ${theme.spacing(2)}`,
}));

const MenuLink = styled(Link)`
  display: flex;
`;

const NavListItem = styled('li')``;

interface NavBarProps {
  setNavPortalNode: (node: HTMLDivElement) => void;
  hideNav: boolean;
  isAuthenticated: boolean;
}

interface NavMenuProps extends Pick<NavBarProps, 'isAuthenticated'> {}
const buttonId = 'nav-menu-button';
const menuId = 'nav-menu';

// Adapted from https://mui.com/material-ui/react-menu/#main-content=
function NavMenu({ isAuthenticated }: NavMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box marginLeft={isAuthenticated ? 'auto' : undefined}>
        <IconButton
          aria-controls={open ? menuId : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          aria-label="Menu"
          id={buttonId}
          onClick={handleClick}
        >
          <MuiMenuIcon />
        </IconButton>
      </Box>
      <Menu
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        anchorEl={anchorEl}
        id={menuId}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          {NAV_LINKS.map(({ href, label, MenuIcon }) => (
            <MenuItem key={href}>
              <MenuLink href={href} underline={false}>
                <ListItemIcon>
                  <MenuIcon />
                </ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </MenuLink>
            </MenuItem>
          ))}
          <Divider />
          {isAuthenticated && (
            <MenuItem>
              <MenuLink href={USERS_SETTINGS_ROUTE} underline={false}>
                <ListItemIcon>
                  <ManageAccounts />
                </ListItemIcon>
                <ListItemText>Profile Settings</ListItemText>
              </MenuLink>
            </MenuItem>
          )}

          <MenuItem onClick={() => (isAuthenticated ? signOut() : signIn())}>
            <ListItemIcon>
              {isAuthenticated ? <Logout /> : <Login />}
            </ListItemIcon>
            <ListItemText>
              {isAuthenticated ? 'Log Out' : 'Log In'}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

export function NavBar({
  setNavPortalNode,
  hideNav,
  isAuthenticated,
}: NavBarProps) {
  const smUp = useBreakpointUp('sm');
  return hideNav ? null : (
    <>
      <NavProgress />
      <NavHeader>
        <LogoRow paddingY={2}>
          <HomeLink isAuthenticated={isAuthenticated} />
          <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="flex-end"
            ref={setNavPortalNode}
          />
          {!isAuthenticated && (
            <Button
              sx={{
                marginLeft: 'auto',
              }}
              variant="contained"
              onClick={() => signIn()}
            >
              Log In
            </Button>
          )}
          <NavMenu isAuthenticated={isAuthenticated} />
        </LogoRow>
      </NavHeader>
      {smUp && (
        <Box display="flex" justifyContent="center" width="100%">
          <Nav>
            {NAV_LINKS.map(({ href, label }, i) => (
              <Fragment key={href}>
                {Boolean(i) && <Divider flexItem orientation="vertical" />}
                <NavListItem>
                  <NavLink href={href} label={label} />
                </NavListItem>
              </Fragment>
            ))}
          </Nav>
        </Box>
      )}
    </>
  );
}
