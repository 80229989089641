import pluralize from 'pluralize';

import { CampaignWithNodeTypes } from '~/typings/complexPrisma';

import {
  CAMPAIGNS,
  CREATE,
  EVENTS,
  GENERATE,
  MAPS,
  NODE_TYPES,
  NODES,
  PEOPLE,
  PLACES,
  QUERY_CAMPAIGN,
  QUERY_HOUSE,
  RELATIONSHIPS,
  Route,
  STATIC_MAPS,
  TAB_QUERY,
  USERS,
} from './shared';

const EDIT = 'edit';
export const HOME_PATH = '/';

// START - CAMPAIGN ROUTES - START
export const CAMPAIGNS_PATH = `/${CAMPAIGNS}` as const;

export const CAMPAIGN_DETAIL_TABS = ['events', 'maps', 'details'] as const;
export const buildCampaignDetailTabs = (campaign: CampaignWithNodeTypes) => {
  const nodeTypeMap = campaign.node_types.map((nt) =>
    pluralize(nt.name.toLowerCase())
  );
  return ['overview', ...nodeTypeMap, ...CAMPAIGN_DETAIL_TABS];
};
export const buildCampaignDetailRoute = (
  id: string,
  tab?: (typeof CAMPAIGN_DETAIL_TABS)[number]
): Route => `${CAMPAIGNS_PATH}/${id}${tab ? `?${TAB_QUERY}=${tab}` : ''}`;
export const buildCampaignEditRoute = (id: string): Route =>
  `${buildCampaignDetailRoute(id)}/${EDIT}`;
export const CAMPAIGNS_CREATE_PATH = buildCampaignDetailRoute(CREATE);
// END - CAMPAIGN ROUTES - END

// START - NODE ROUTES - START
export const NODES_PATH = `/${NODES}` as const;
export const buildNodePath = (campaignId: string): Route =>
  `${buildCampaignDetailRoute(campaignId)}${NODES_PATH}`;
export const buildNodeCreatePath = (
  campaignId: string,
  nodeType: string
): Route =>
  `${CAMPAIGNS_PATH}/${campaignId}/${nodeType?.toLocaleLowerCase()}/${CREATE}`;
export const buildNodeDetailPath = (id: string): Route => `${NODES_PATH}/${id}`;
export const buildNodeEditPath = (id: string): Route =>
  `${buildNodeDetailPath(id)}/${EDIT}`;
export const buildNodeEventPath = (id: string): Route =>
  `${buildNodeDetailPath(id)}/${EVENTS}`;
export const buildNodePeoplePath = (id: string): Route =>
  `${buildNodeDetailPath(id)}/${PEOPLE}/${GENERATE}`;
// END - NODE ROUTES - END

// START - MAPS ROUTES - START
export const MAPS_PATH = `/${MAPS}` as const;
export const buildCampaignMapsRoute = (id: string): Route =>
  `${CAMPAIGNS_PATH}/${id}${MAPS_PATH}`;
export const buildCampaignMapDetailRoute = (
  campaignId: string,
  mapId: string
): Route => `${buildCampaignMapsRoute(campaignId)}/${mapId}`;
export const buildMapDetailRoute = (id: string): Route => `${MAPS_PATH}/${id}`;
export const GENERATE_MAP_ROUTE = `${MAPS_PATH}/${GENERATE}`;
// END - MAPS ROUTES - END

// START - STATIC MAPS ROUTES - START
export const STATIC_MAPS_PATH = `/${STATIC_MAPS}` as const;
export const buildCampaignStaticMapsRoute = (id: string): Route =>
  `${CAMPAIGNS_PATH}/${id}${STATIC_MAPS_PATH}`;
export const buildCampaignStaticMapsDetailRoute = (
  campaignId: string,
  mapId: string
): Route => `${buildCampaignStaticMapsRoute(campaignId)}/${mapId}`;
// END - STATIC MAPS ROUTES - END

// START - PEOPLE ROUTES - START
export const PEOPLE_PATH = `/${PEOPLE}` as const;
export const GENERATE_PEOPLE_PATH: Route = `${PEOPLE_PATH}/${GENERATE}`;
export const buildAddPersonPath = (
  query: typeof QUERY_HOUSE | typeof QUERY_CAMPAIGN,
  id: string
): Route => `${GENERATE_PEOPLE_PATH}?${query}=${id}`;
// END - PEOPLE ROUTES - END

// START - PLACE ROUTES - START
export const PLACE_PATH = `/${PLACES}` as const;
export const buildPlaceRoute = (id: string): Route => `${PLACE_PATH}/${id}`;
export const GENERATE_PLACE_ROUTE = buildPlaceRoute(GENERATE);
// END - PLACE ROUTES - END

// START - RELATIONSHIPS ROUTES - START
export const buildNodeRelationshipsPath = (id: string) =>
  `${NODES_PATH}/${id}/${RELATIONSHIPS}`;
// END - ROUTES - END

// START - EVENT ROUTES - START
export const buildCampaignEventTemplatesPath = (id: string) =>
  `/${CAMPAIGNS}/${id}/${EVENTS}/templates`;

export const buildCreateEventTemplatesPath = (campaignId: string) =>
  `/${CAMPAIGNS}/${campaignId}/${EVENTS}/create`;
// END - EVENT ROUTES - END

// START - NODE TYPE ROUTES - START
export const buildCampaignNodeTypesEditPath = (id: string) =>
  `/${CAMPAIGNS}/${id}/${NODE_TYPES}/${EDIT}`;

// START - USERS ROUTES - START
export const USERS_PATH = `/${USERS}` as const;
export const USERS_SETTINGS_ROUTE = `${USERS_PATH}/settings`;
export const USERS_WELCOME_ROUTE = `${USERS_PATH}/welcome`;
// END - USERS ROUTES - END

export const PUBLIC_ROUTES = [
  HOME_PATH,
  GENERATE_PEOPLE_PATH,
  GENERATE_PLACE_ROUTE,
  GENERATE_MAP_ROUTE,
];
