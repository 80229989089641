import {
  CAMPAIGNS,
  CREATE,
  EVENT_TEMPLATES,
  EVENTS,
  GENERATE,
  MAPS,
  NODES,
  PEOPLE,
  PLACES,
  RELATIONSHIPS,
  Route,
  STATIC_MAPS,
  USER,
  USERS,
} from './shared';

export const API_PATH = '/api' as const;
const EDIT = 'edit';
const BULK_CREATE = 'bulk-create';
// START - API EVENTS ROUTES - START
export const EVENT_TRIGGER_API_ROUTE = `${API_PATH}/${EVENTS}/trigger`;
export const AI_EVENT_TRIGGER_API_ROUTE = `${API_PATH}/trigger-ai-event`;
export const EVENT_CREATE_API_ROUTE = `${API_PATH}/${EVENTS}/${CREATE}`;
// END - API EVENTS ROUTES - END

// START - API EVENT TEMPLATES ROUTES - START
export const EVENT_TEMPLATE_API_ROUTE =
  `${API_PATH}/${EVENT_TEMPLATES}` as const;
export const CREATE_EVENT_TEMPLATE_API_ROUTE = `${EVENT_TEMPLATE_API_ROUTE}/${CREATE}`;
export const BULK_EVENT_TEMPLATE_API_ROUTE = `${EVENT_TEMPLATE_API_ROUTE}/${BULK_CREATE}`;

export const buildEventTemplatesApiRoute = (id: string) =>
  `${EVENT_TEMPLATE_API_ROUTE}/${id}`;
// END - API EVENT TEMPLATES ROUTES - END

// START - API CAMPAIGN ROUTES - START
export const CAMPAIGNS_API_ROUTE = `${API_PATH}/${CAMPAIGNS}` as const;
export const buildCampaignDetailApiRoute = (id: string): Route =>
  `${API_PATH}/${CAMPAIGNS}/${id}`;
export const INVITE_USER_API_ROUTE: Route = `${API_PATH}/${CAMPAIGNS}/invite`;
export const CREATE_CAMPAIGNS_API_ROUTE: Route = `${CAMPAIGNS_API_ROUTE}/${CREATE}`;
export const buildUserCampaignsApiRoute = (id: string): Route =>
  `${CAMPAIGNS_API_ROUTE}/${USER}/${id}`;
export const buildCampaignMapsApiRoute = (id: string): Route =>
  `${buildCampaignDetailApiRoute(id)}/${MAPS}`;
export const buildCampaignEventsApiRoute = (id: string): Route =>
  `${buildCampaignDetailApiRoute(id)}/${EVENTS}`;
export const DELETE_CAMPAIGNS_API_ROUTE = `${CAMPAIGNS_API_ROUTE}/delete`;
// END - API CAMPAIGN ROUTES - END

// START - API NODE ROUTES - START
export const NODES_API_ROUTE = `${API_PATH}/${NODES}` as const;
export const buildNodeDetailApiRoute = (id: string): Route =>
  `${NODES_API_ROUTE}/${id}`;
export const NODE_EDIT_API_ROUTE = buildNodeDetailApiRoute(EDIT);
export const NODE_CREATE_API_ROUTE = buildNodeDetailApiRoute(CREATE);
export const buildNodeEventsApiRoute = (id: string): Route =>
  `${buildNodeDetailApiRoute(id)}/${EVENTS}`;
export const buildNodePeopleApiRoute = (id: string): Route =>
  `${buildNodeDetailApiRoute(id)}/${PEOPLE}`;
// END - API NODE ROUTES - END

// START - API MAP ROUTES - START
export const MAPS_API_ROUTE = `${API_PATH}/${MAPS}` as const;
export const buildMapDetailApiRoute = (id: string): Route =>
  `${MAPS_API_ROUTE}/${id}`;
export const MAPS_CREATE_API_ROUTE = buildMapDetailApiRoute(CREATE);
// END API MAP ROUTES - END

// START - API STATIC MAP ROUTES - START
export const STATIC_MAPS_API_ROUTE = `${API_PATH}/${STATIC_MAPS}` as const;
export const buildStaticMapDetailApiRoute = (id: string): Route =>
  `${STATIC_MAPS_API_ROUTE}/${id}`;
export const STATIC_MAPS_CREATE_API_ROUTE =
  buildStaticMapDetailApiRoute(CREATE);
// END API MAP ROUTES - END

// START - API RELATIONSHIPS
export const buildNodeRelationshipsApiRoute = (id: string): Route =>
  `${NODES_API_ROUTE}/${id}/${RELATIONSHIPS}`;
// END API RELATIONSHIPS

// START - API PEOPLE ROUTES - START
export const PEOPLE_API_ROUTE = `${API_PATH}/${PEOPLE}` as const;
export const CREATE_PERSON_API_ROUTE = `${PEOPLE_API_ROUTE}/${CREATE}`;
export const GENERATE_PERSON_API_ROUTE = `${PEOPLE_API_ROUTE}/${GENERATE}`;
// END - API PEOPLE ROUTES - END

// START - API PLACES ROUTES - START
export const PLACE_API_ROUTE = `${API_PATH}/${PLACES}` as const;
export const CREATE_PLACE_API_ROUTE = `${PLACE_API_ROUTE}/${CREATE}`;
export const GENERATE_PLACE_API_ROUTE = `${PLACE_API_ROUTE}/${GENERATE}`;
// END - API PLACES ROUTES - END

// START - MAP NODES API ROUTES - START
export const MAP_NODES_API_ROUTE = `${API_PATH}/map-nodes` as const;
export const MAP_NODES_CREATE_API_ROUTE = `${MAP_NODES_API_ROUTE}/${CREATE}`;
export const buildMapNodeDetailApiRoute = (id: string) =>
  `${MAP_NODES_API_ROUTE}/${id}`;
export const buildMapNodeUpdateApiRoute = (id: string) =>
  `${MAP_NODES_API_ROUTE}/${id}/update`;
// END - MAP NODES API ROUTES - END

// START - API USERS ROUTES - START
export const USERS_API_ROUTE = `${API_PATH}/${USERS}` as const;
export const USERS_ME_API_ROUTE = `${USERS_API_ROUTE}/me` as const;
// END - API USER ROUTES - END
